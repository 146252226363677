import moment from 'moment-timezone';
import constants from 'components/common/Constants/constants';
import colorsVars from 'styles/colors.module.scss';
import { useThemeStatusStore } from 'store/themeStatus.store';
import { formatValueForGraph, roundNumber } from 'utilitys/helpers/general';
import { QueryResponse } from 'api/queries/getCriticalityWeightScurveGraph.query';

const { plannedColor, actualColor, forecatsColor, redStatusColor, statusSummaryOrange } = colorsVars;

const colors = {
    plannedGraphLine: plannedColor,
    actualGraphLine: actualColor,
    forecatsGraphLine: forecatsColor,
};

interface DataSeries {
    name: string;
    data: number[];
    color: string;
    labels: string;
    isDashed: boolean;
}

const getLineColor = (name: string) => {
    const themeStatus = useThemeStatusStore.getState().themeStatus;
    if (name.includes('Planned')) return themeStatus ? '#94a1b7' : '#262b33';
    if (name.includes('Actual')) return themeStatus ? '#29fd48' : '#63a450';
    if (name.includes('Current')) return themeStatus ? '#17cdff' : '#32a4bc';
};

export const serializeData = (data: QueryResponse | undefined): DataSeries[] => {
    if (!data) return [];

    const dataset: DataSeries[] = [];
    Object.keys(data).forEach((key) => {
        if (data[key].title && data[key].data.length) {
            const name = data[key].seriesname || data[key].title;
            const series: DataSeries = {
                data: data[key].data.map((i) => i.value),
                name: name,
                color: getLineColor(name) || colors[key],
                labels: data[key].data.map((i) => i.text),
                isDashed: name.includes('Forecast'),
            };
            dataset.push(series);
        }
    });
    return dataset;
};

const getCategories = (series: DataSeries[] | undefined) => {
    if (!series) return [];

    const lengths = series.map((a) => a.labels.length);
    const longestIndex = lengths.indexOf(Math.max(...lengths));
    return longestIndex > -1 ? series[longestIndex].labels : [];
};

const getGapColor = (value: number) => {
    if (!value) return '#94a1b7';
    if (value > 10) return redStatusColor;
    if (value >= 1 && value < 10) return statusSummaryOrange;
    return '#94a1b7';
};

const getDateLabelPosition = (plannedValue: number | undefined) => {
    if (plannedValue) {
        if (plannedValue > 50) {
            return { offsetY: -50, position: 'bottom' };
        }
    }

    return { offsetY: 50, position: 'top' };
};

interface serializeOptionsProps {
    series: DataSeries[] | undefined;
    nowDate: string | undefined;
    themeStatus: boolean;
    isShowAnnotation: boolean;
    pointsAnnotation: { plannedValue: number; actualValue: number; gap: number | false } | null;
}

export const serializeOptions = ({
    series,
    nowDate,
    themeStatus,
    isShowAnnotation,
    pointsAnnotation,
}: serializeOptionsProps): ApexCharts.ApexOptions => {
    const categories = getCategories(series);
    const dashArray = series?.map((item) => (item.isDashed ? 5 : 0));
    const dateLabelPosition = getDateLabelPosition(pointsAnnotation?.plannedValue);
    const annotation =
        isShowAnnotation && pointsAnnotation
            ? [
                  {
                      x: moment(nowDate).valueOf(),
                      y: pointsAnnotation.plannedValue,
                      seriesIndex: 3,
                      marker: {
                          size: 8,
                          strokeColor: themeStatus ? '#94a1b7' : '#262b33',
                      },
                      label: {
                          borderColor: themeStatus ? '#000000' : '#ffffff',
                          text: pointsAnnotation.plannedValue ? `Planned: ${pointsAnnotation.plannedValue}%` : '',
                          style: {
                              color: themeStatus ? '#94a1b7' : '#262b33',
                              background: themeStatus ? '#212121' : '#eaecee',
                              fontSize: '1.6rem',
                          },
                          offsetY: 5,
                          offsetX: -85,
                      },
                  },
                  {
                      x: moment(nowDate).valueOf(),
                      y: pointsAnnotation.actualValue,
                      marker: {
                          size: 8,
                          strokeColor: getGapColor(pointsAnnotation.actualValue),
                      },
                      label: {
                          borderColor: themeStatus ? '#000000' : '#ffffff',
                          text:
                              pointsAnnotation.actualValue && pointsAnnotation.gap
                                  ? `Actual: ${pointsAnnotation.actualValue}% Gap: ${pointsAnnotation.gap}%`
                                  : pointsAnnotation.actualValue
                                    ? `Actual: ${pointsAnnotation.actualValue}%`
                                    : '',
                          style: {
                              color: getGapColor(pointsAnnotation.actualValue),
                              background: themeStatus ? '#212121' : '#eaecee',
                              fontSize: '1.6rem',
                          },
                          offsetY: 15,
                          offsetX: 120,
                      },
                  },
              ]
            : [];

    return {
        chart: {
            background: themeStatus ? '#000000' : '#ffffff',
            foreColor: themeStatus ? '#FEFEFE' : '#000000',
            toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: false,
                    selection: false,
                    zoomin: true,
                    zoomout: true,
                    pan: false,
                    reset: true,
                },
            },
            events: {
                beforeZoom(chart: any) {
                    if (chart.wheelDelay) {
                        return {
                            xaxis: {
                                min: chart.minX,
                                max: chart.maxX,
                            },
                        };
                    }
                    return undefined;
                },
            },
        },
        legend: {
            itemMargin: {
                horizontal: 10,
                vertical: 25,
            },
        },
        xaxis: {
            type: 'datetime',
            categories: categories,
            labels: {
                datetimeUTC: false,
            },
        },
        stroke: {
            width: 4,
            dashArray: dashArray,
        },
        yaxis: {
            min: 0,
            max: 100,
            show: true,
            decimalsInFloat: 2,
            labels: {
                style: {
                    fontSize: '1.2rem',
                },
                formatter: (value) => {
                    return formatValueForGraph(value);
                },
            },
        },
        markers: {
            size: 5,
        },
        annotations: {
            xaxis: [
                {
                    x: moment(nowDate).valueOf(),
                    borderColor: '#d580ff',
                    label: {
                        borderColor: '#d580ff',
                        text: moment(nowDate).format(constants.formats.date.monthAndYear),
                        orientation: 'horizontal',
                        ...dateLabelPosition,
                        style: {
                            color: '#d580ff',
                            background: '#ffffff',
                            fontSize: '1.2rem',
                        },
                    },
                },
            ],
            points: [...annotation],
        },
        tooltip: {
            theme: themeStatus ? 'dark' : 'light',
        },
    };
};

export const getPoints = (data: QueryResponse | undefined) => {
    if (data) {
        const planned = data.plannedGraphLine.data?.find((item) =>
            item.text.includes(moment(data.nowDate).format(constants.formats.date.default)),
        )?.value;

        const actual = data.actualGraphLine.data?.find((item) =>
            item.text.includes(moment(data.nowDate).format(constants.formats.date.default)),
        )?.value;

        const plannedValue = planned ? roundNumber(planned) : NaN;
        const actualValue = actual ? roundNumber(actual) : NaN;

        const gap = !isNaN(plannedValue) && !isNaN(actualValue) && roundNumber(plannedValue - actualValue);

        return { plannedValue, actualValue, gap };
    }

    return null;
};
