import React, { useEffect, useMemo } from 'react';
import { WidgetWithTitle } from 'components/common/WidgetWithTitle/WidgetWithTitle';
import { ExportDropdown } from 'components/common/ExportDropdown/exportDropdown';
import { AddWidget } from 'components/Dashboards/Project/Components/CustomDashboard/AddRemoveWidget/addWidget';
import { WidgetFilters } from 'components/Dashboards/Project/Components/CustomDashboard/WidgetFilters/widgetFilters';
import { FilterNames, getFiltersData } from 'components/common/GlobalFilterComponent/GlobalFilterComponent.utils';
import { IComponentProps } from 'components/Dashboards/Project/Components/CustomDashboard/utils/utils.type';

import { useGlobalFiltersHook } from 'hooks/useGlobalFiltersHook';
import useCustomPageSubTitle from 'hooks/useCustomPageSubTitle';
import { ShareInsightButton } from 'components/common/ShareInsightButton/ShareInsightButton';
import SwitchComponent from 'components/common/Switch/Switch';
import { useGraphAnnotationStore } from 'store/graphAnnotation.store';
import classes from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisIntensity/criticalAnalysisIntensity.module.scss';
import { LineChart } from 'components/common/charts/lineChart/lineChart';
import { useQueryGetCriticalityWeightScurveGraph } from 'api/queries/getCriticalityWeightScurveGraph.query';
import { useThemeStatusStore } from 'store/themeStatus.store';
import {
    getPoints,
    serializeData,
    serializeOptions,
} from 'components/Dashboards/Program/Dashboard/ProgramDashboardComponents/CommonComponents/CriticalAnalysisIntensityComponent/utils';
import { getStringWithoutHash } from 'utilitys/helpers/general';

const RenderCriticalAnalysisIntensity = ({
    projectId,
    contractId,
    widgetId,
    latestVersionId,
    compareVersionId,

    filter,
    globalFilterData,
    externalComponents = null,
    editNarrative = null,
    setSubTitleForExport,
}: IComponentProps) => {
    const idList = useMemo(() => (latestVersionId ? [latestVersionId] : []), [latestVersionId]);
    const isShowCriticalityAnnotation = useGraphAnnotationStore((store) => store.isShowCriticalityAnnotation);
    const { update } = useGraphAnnotationStore();
    const filters = useMemo(
        () =>
            widgetId
                ? filter?.filters
                : getFiltersData({
                      [FilterNames.ACTIVITY_CODES]: globalFilterData?.activityCodes
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.ACTIVITY_CODES_EXCLUDE]: globalFilterData?.activityCodes
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.FRAGNET_SEARCH]: globalFilterData?.milestoneFragnet,
                      [FilterNames.TAG_CARD_BOARD]: globalFilterData?.tags
                          ?.filter((item) => !item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.TAG_CARD_BOARD_EXCLUDE]: globalFilterData?.tags
                          ?.filter((item) => item.value.isExclude)
                          .map((i) => i.value.name),
                      [FilterNames.WBS_SEARCH]: globalFilterData?.wbs,
                  }),

        [filter, globalFilterData],
    );
    const { generateFilters: formatedFilter } = useGlobalFiltersHook({ widgetId, filter, latestVersionId });
    const { data: criticality, isFetching: criticalityFetching } = useQueryGetCriticalityWeightScurveGraph({
        id: [idList],
        latestVersion: latestVersionId,
        comparedVersion: compareVersionId || latestVersionId,
        filter: {
            filters,
        },
    });

    const title = 'Criticality Weighted S-Curve';
    const componentKey = 'criticalityWeightedSCurve';
    const id = 'IntensityCriticalityWeight';
    const route = `${getStringWithoutHash(window.location.href)}#${id}`;
    const subTitle = useCustomPageSubTitle({ projectId, contractId, latestVersionId });
    useEffect(() => {
        setSubTitleForExport && setSubTitleForExport({ widgetId, subTitle, title });
    }, [widgetId, subTitle, title]);

    const { themeStatus } = useThemeStatusStore();
    const pointsAnnotation = getPoints(criticality);
    const series = serializeData(criticality);
    const options = serializeOptions({
        series,
        nowDate: criticality?.nowDate,
        themeStatus,
        isShowAnnotation: isShowCriticalityAnnotation,
        pointsAnnotation,
    });

    return (
        <WidgetWithTitle
            id={id}
            title={title}
            tooltip={criticality?.info}
            titleComponents={[
                <div className={classes.annotationFilter}>
                    {isShowCriticalityAnnotation ? 'Hide Annotations' : 'Show Annotations'}
                    <SwitchComponent
                        onChange={(event) => update({ isShowCriticalityAnnotation: event.target.checked })}
                        checked={isShowCriticalityAnnotation}
                    />
                </div>,
                externalComponents && <div key={'externalComponents'}>{externalComponents}</div>,
                <AddWidget
                    key={'AddWidget'}
                    componentKey={componentKey}
                    title={title}
                    projectId={projectId}
                    contractId={contractId}
                    widgetId={widgetId}
                    route={route}
                    filters={filters}
                />,
                <ShareInsightButton key={'shareInsight'} title={title} link={route} />,
                <ExportDropdown key={'export'} title={title} subTitle={subTitle} />,
            ]}
            titleFilters={[
                editNarrative && <div key={'editNarrative'}>{editNarrative}</div>,
                widgetId && formatedFilter.length > 0 ? (
                    <WidgetFilters key={'widgetFilters'} widgetId={widgetId} filters={formatedFilter} />
                ) : null,
            ]}
        >
            <LineChart
                series={series}
                options={options}
                noData={
                    !criticalityFetching &&
                    criticality?.plannedGraphLine?.data.length === 0 &&
                    criticality?.actualGraphLine?.data.length === 0
                }
                isFetching={criticalityFetching}
                className={classes.lineChart}
            />
        </WidgetWithTitle>
    );
};

export default React.memo(RenderCriticalAnalysisIntensity);
